import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes: Array<RouteRecordRaw> = [
    {
        path: "/login",
        name: "login",
        // route level code-splitting
        // this generates a separate chunk (login.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "login" */ "../views/LoginView.vue"),
    },
    {
        path: "/",
        name: "home",
        component: HomeView,
        redirect: "/welcome",
        children: [
            {
                path: "/welcome",
                name: "welcome",
                meta: {
                    isShow: true,
                    title: "控制台",
                },
                // route level code-splitting
                // this generates a separate chunk (welcome.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "welcome" */ "../views/WelcomeView.vue"),
            },
            {
                path: "/user",
                name: "user",
                meta: {
                    isShow: true,
                    title: "用户列表",
                },
                // route level code-splitting
                // this generates a separate chunk (user.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "user" */ "../views/UserView.vue"),
            },
            {
                path: "/adRecord",
                name: "adRecord",
                meta: {
                    isShow: true,
                    title: "看广告记录",
                },
                // route level code-splitting
                // this generates a separate chunk (adRecord.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "adRecord" */ "../views/AdRecordView.vue"),
            },
            {
                path: "/exchange",
                name: "exchange",
                meta: {
                    isShow: true,
                    title: "提现列表",
                },
                // route level code-splitting
                // this generates a separate chunk (exchange.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "exchange" */ "../views/ExchangeView.vue"),
            },
            {
                path: "/score",
                name: "score",
                meta: {
                    isShow: true,
                    title: "积分列表",
                },
                // route level code-splitting
                // this generates a separate chunk (score.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "score" */ "../views/ScoreView.vue"),
            },
            {
                path: "/notice",
                name: "notice",
                meta: {
                    isShow: true,
                    title: "公告列表",
                },
                // route level code-splitting
                // this generates a separate chunk (notice.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "notice" */ "../views/NoticeView.vue"),
            },
            {
                path: "/lotteryRecord",
                name: "lotteryRecord",
                meta: {
                    isShow: true,
                    title: "转盘记录",
                },
                // route level code-splitting
                // this generates a separate chunk (gameConfig.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "gameConfig" */ "../views/LotteryRecordView.vue"),
            },
            {
                path: "/lotteryConfig",
                name: "lotteryConfig",
                meta: {
                    isShow: true,
                    title: "转盘配置",
                },
                // route level code-splitting
                // this generates a separate chunk (gameConfig.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "gameConfig" */ "../views/LotteryConfigView.vue"),
            },
            {
                path: "/gameConfig",
                name: "gameConfig",
                meta: {
                    isShow: true,
                    title: "游戏配置",
                },
                // route level code-splitting
                // this generates a separate chunk (gameConfig.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "gameConfig" */ "../views/GameConfigView.vue"),
            },
            {
                path: "/cashRecord",
                name: "cashRecord",
                meta: {
                    isShow: true,
                    title: "打击侵略者提现",
                },
                // route level code-splitting
                // this generates a separate chunk (gameConfig.[hash].js) for this route
                // which is lazy-loaded when the route is visited.
                component: () => import(/* webpackChunkName: "gameConfig" */ "../views/CashRecordView.vue"),
            },
        ],
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("token");

    if (!token && to.path !== "/login") {
        return next("/login");
    }

    next();
});

export default router;
