import { ReqAdRecordList } from "@/typings/adRecord";
import { ReqExchange, ReqExchangeClose, ReqExchangeGive } from "@/typings/exchange";
import { GameConfig, ReqGameConfig } from "@/typings/gameConfig";
import { LoginData } from "@/typings/login";
import { ReqLotteryConfig, ReqLotteryRecord } from "@/typings/lottery";
import { ReqNotice, ReqNoticeDelete, ReqNoticeList, ReqNoticeModify, ReqNoticePop } from "@/typings/notice";
import { AddQuizInfo, ReqQuizList } from "@/typings/quiz";
import { ReqScoreGive, ReqScoreGiveAll, ReqScoreList } from "@/typings/score";
import { ReqEnableUser, ReqIsTestUser, ReqModifyPasswd, ReqUserList } from "@/typings/userInfo";
import http from ".";
import { ReqCashClose, ReqCashGive, ReqCashRecord } from "@/typings/cashRecord";

const post = (url: string, data?: unknown, timeout?: number) => http({ url, method: "post", data, timeout });

/**
 * 登录
 * @param data
 * @returns
 */
export const login = (data: LoginData) => post("/login", data);

/**
 * 获取用户列表
 * @param data
 * @returns
 */
export const getUserList = (data: ReqUserList) => post("/users", data);

/**
 * 修改用户账号是否禁用
 * @param data
 * @returns
 */
export const enableUser = (data: ReqEnableUser) => post("/user/enable", data);

/**
 * 修改账号是否是测试账号
 * @param data
 * @returns
 */
export const isTestUser = (data: ReqIsTestUser) => post("/user/isTest", data);

/**
 * 获取32强所有国家列表
 * @returns
 */
export const getCountryList = () => post("/country");

/**
 * 获取竞猜列表
 * @param data
 * @returns
 */
export const getQuizList = (data: ReqQuizList) => post("/quiz", data);

/**
 * 添加竞猜列表
 * @param data
 * @returns
 */
export const addQuizInfo = (data: AddQuizInfo) => post("/addQuiz", data);

/**
 * 获取兑换列表
 * @param data
 * @returns
 */
export const getExchangeRecordList = (data: ReqExchange) => post("/exchange", data);

/**
 * 确认兑换
 * @param data
 * @returns
 */
export const exchangeGive = (data: ReqExchangeGive) => post("/exchange/give", data);

/**
 * 关闭兑换订单
 * @param data
 * @returns
 */
export const exchangeClose = (data: ReqExchangeClose) => post("/exchange/close", data);

/**
 * 获取配置列表
 * @param data
 * @returns
 */
export const getGameConfigList = (data: ReqGameConfig) => post("/gameConfig", data);

/**
 * 修改配置
 * @param data
 * @returns
 */
export const modifyGameConfig = (data: GameConfig) => post("/gameConfig/modify", data);

/**
 * 获取统计数据
 * @returns
 */
export const getStatistics = () => post("/statistics");

/**
 * 获取积分列表
 * @param data
 * @returns
 */
export const getScoreList = (data: ReqScoreList) => post("/score", data);

/**
 * 发放音浪
 * @returns
 */
export const giveAll = (data: ReqScoreGiveAll) => post("/score/giveAll", data, 10 * 60 * 1000);

/**
 * 单个发放音浪
 * @param data
 * @returns
 */
export const giveYinlang = (data: ReqScoreGive) => post("/score/give", data);

/**
 * 获取昨日数据
 * @returns
 */
export const getYesterdayData = () => post("/score/yesterdayData");

/**
 * 获取公告列表
 * @param data
 * @returns
 */
export const getNoticeList = (data: ReqNoticeList) => post("/notices", data);

/**
 * 发布公告
 * @param data
 * @returns
 */
export const publishNotice = (data: ReqNotice) => post("/notice/publish", data);

/**
 * 修改公告是否弹窗
 * @param data
 * @returns
 */
export const noticePopChange = (data: ReqNoticePop) => post("/notice/changePop", data);

/**
 * 删除公告
 * @param data
 * @returns
 */
export const noticeDelete = (data: ReqNoticeDelete) => post("/notice/delete", data);

/**
 * 修改公告
 * @param data
 * @returns
 */
export const modifyNotice = (data: ReqNoticeModify) => post("/notice/modify", data);

/**
 * 看广告次数记录
 * @param data
 * @returns
 */
export const getAdRecordList = (data: ReqAdRecordList) => post("/adRecord", data);

/**
 * 获取转盘配置
 * @returns
 */
export const getLotteryConfigList = () => post("/lottery/config");

/**
 *
 * @param data
 * @returns
 */
export const updateLotteryConfig = (data: ReqLotteryConfig) => post("/lottery/update", data);

/**
 * 转盘记录
 * @param data
 * @returns
 */
export const getLotteryRecordList = (data: ReqLotteryRecord) => post("/lottery/record", data);

/**
 * 修改密码
 * @param data
 * @returns
 */
export const modifyPasswd = (data: ReqModifyPasswd) => post("/user/modifyPasswd", data);

// td

/**
 * 获取提现记录
 * @param data
 * @returns
 */
export const getCashRecordList = (data: ReqCashRecord) => post("/cashRecord", data);

/**
 * 发放提现
 * @param data
 * @returns
 */
export const cashGive = (data: ReqCashGive) => post("/cashRecord/give", data);

/**
 * 关闭订单
 * @param data
 * @returns
 */
export const cashClose = (data: ReqCashClose) => post("/cashRecord/close", data);
