<template>
    <div id="app">
        <router-view />
    </div>
</template>

<style lang="scss">
* {
    padding: 0;
    margin: 0;
}
html,
body,
#app {
    width: 100%;
    height: 100%;
}

.el-pagination {
    margin-top: 30px;
}
</style>
